import { DataContentStatus } from "./app";
import { TableColumn } from "./table";

export interface CampaignsState {
  campaigns: Campaign[];
  extendedCampaigns: ExtendedCampaign[];
  campaignsTableCols: { all: TableColumn[]; temporarilySelected: TableColumn[] };
  dataContentStatus: DataContentStatus;
}

export interface Campaign {
  id: number;
  src: any;
  name: string;
  nameEnum: string;
  campaignName: string;
  workspaceId: number;
  sumFbSpend: number;
  sumFbImpressions: number | null;
  sumFbCPM: number | null;
  sumFbClicks: number | null;
  sumFbCPC: number | null;
  sumFbCTR: number | null;
  sumFbOmniPurchase: number | null;
  sumFbOmniPurchaseValue: number | null;
  roas?: number;

  sumSpend: number;
  sumImpressions?: number;
  sumCPMFL: number | null;
  cpmFL?: number;
  sumClicks?: number;
  sumFraudClicks?: number;
  sumCPCFL: number | null;
  sumCTRFl: number | null;
  cpcFL?: number;
  ctrFL?: number;
  sumPurchases?: number;
  sumPurchasesValue?: number;
  roasFL?: number;
  purchasesValue: number;
  reportedVsRevenue: number;
  campaigns?: Campaign[] | ExtendedCampaign[];
}

export interface ExtendedCampaign {
  id: number;
  campaignName: string;
  workspaceId: number;
  purchasesValue: number;
  sumFbClicks: number | null;
  sumFbImpressions: number | null;
  sumFbCPM: number | null;
  sumFbCPC: number | null;
  sumFbCTR: number | null;
  sumCPMFL: number | null;
  sumCPCFL: number | null;
  sumCTRFl: number | null;
  sumFbOmniPurchase: number | null;
  sumFbOmniPurchaseValue: number | null;
  sumFbSpend: number;
  sumSpend: number;
  sumPurchases?: number;
  cpmFL?: number;
  cpcFL?: number;
  ctrFL?: number;
  sumClicks?: number;
  sumFraudClicks?: number;
  sumImpressions?: number;
  sumPurchasesValue?: number;
  roas?: number;
  roasFL?: number;
  src: any;
  reportedVsRevenue: number;
  campaigns?: Campaign[] | ExtendedCampaign[];
  expanded?: boolean;
  lastExpanded?: boolean;
  main?: boolean;
  adsets?: ExtendedCampaign[];
  ads?: ExtendedCampaign[];
  rootId?: number;
  parentId?: number;
  depth: number;
  name: string;
  subRows?: Campaign[] | ExtendedCampaign[];
}

export interface CampaignWithAdSets extends Campaign {
  adsets: string[];
  ads: string[];
  rows: Campaign[];
}

export interface CampaignQuery {
  startDate: number;
  endDate: number;
  workspaceId?: number;
}

export interface IAggregatedFacebookMainTemplate {
  sumPurchases: number;
  sumClicks: number;
  sumFraudClicks: number;
  roas: number;
  roasFL: number;
  cpmFL: number;
  cpcFL: number;
  ctrFL: number;
  sumImpressions: number;
  sumPurchasesValue: number;
  sumFbSpend: number;
  sumSpend: number;
  sumFbOmniPurchase: number;
  sumFbOmniPurchaseValue: number;
  sumFbClicks: number;
  sumFbImpressions: number;
  sumFbCPM: number;
  sumFbCPC: number;
  sumFbCTR: number;
  sumCPMFL: number;
  sumCPCFL: number;
  sumCTRFL: number;
}

export interface IAggregatedFacebookSubrowsTemplate extends IAggregatedFacebookMainTemplate {
  name: string;
}

export interface IAggregatedFacebook extends IAggregatedFacebookMainTemplate {
  campaigns: IAggregatedFbCampaign[];
}

export interface IAggregatedFbCampaign extends IAggregatedFacebookSubrowsTemplate {
  id: number;
  workspaceId: number;
  adsets: IAggregatedFbAdset[];
  facebookId: string;
}

export interface IAggregatedFbAdset extends IAggregatedFacebookSubrowsTemplate {
  id: string;
  ads: IAggregatedFbAd[];
}

export interface IAggregatedFbAd {
  id: string;
  name: string;
  facebookId: string;
  purchases: number;
  CPM: number;
  CPC: number;
  CTR: number;
  roas: number;
  clicks: number;
  fraudClicks: number;
  impressions: number;
  purchasesValue: number;
  spend: number;
  fbSpend: number;
  roasFL: number;
  fbOmniPurchase: number;
  fbOmniPurchaseValue: number;
  fbClicks: number;
  fbImpressions: number;
  fbCPM: number;
  fbCPC: number;
  fbCTR: number;
}

export interface IAggregatedCampaigns extends Record<string, unknown> {
  organic: IAggregatedOrganic;
  facebook: IAggregatedFacebook;
  google: IAggregatedGoogle;
  tiktok: IAggregatedTikTok;
  dataContentStatus: DataContentStatus;
}
export interface IAggregatedCampaign {
  sumPurchases: number | null;
  cpmFL: number | null;
  cpcFL: number | null;
  ctrFL: number | null;
  sumImpressions: number | null;
  sumClicks: number | null;
  sumFraudClicks: number | null;
  roas: number | null;
  roasFL: number | null;
  sumPurchasesValue: number | null;
  sumFbSpend: number | null;
  sumSpend: number | null;
  sumFbOmniPurchase: number | null;
  sumFbOmniPurchaseValue: number | null;
  sumFbClicks: number | null;
  sumFbImpressions: number | null;
  sumFbCPM: number | null;
  sumFbCPC: number | null;
  sumFbCTR: number | null;
  sumCPMFL: number | null;
  sumCPCFL: number | null;
  sumCTRFl: number | null;
}

export interface IAggregatedOrganic {
  sumPurchases: number;
  sumPurchasesValue: number;
}

export interface IAggregatedTikTok extends IAggregatedCampaign {
  ads: IAggregatedTikTokAd[];
}

export interface IAggregatedTikTokAd extends IAggregatedCampaign {
  tiktokId: string;
  ttAdId: number;
}

export enum CampaignColsEnum {
  ALL = "all",
  TEMPORARILY_SELECTED = "temporarilySelected",
}

export interface ICampaignColsInput {
  cols: TableColumn[];
  type: CampaignColsEnum;
}

export interface IAggregatedGoogleMainTemplate {
  sumPurchases: number;
  cpmFL: number;
  cpcFL: number;
  ctrFL: number;
  sumImpressions: number;
  sumClicks: number;
  sumFraudClicks: number;
  roas: number;
  roasFL: number;
  sumSpent: number;
  sumGoogleRoas: number;
  sumGoogleFbRoas: number;
  sumPurchasesValue: number;
  sumGooglePurchases: number;
  sumGooglePurchasesValue: number;
  sumGoogleSpent: number;
  sumGoogleClicks: number;
  sumGoogleImpressions: number;
  sumGoogleCPM: number;
  sumGoogleCPC: number;
  sumGoogleCTR: number;
}

export interface IAggregatedGoogleSubrowsTemplate extends IAggregatedGoogleMainTemplate {
  id: number;
  name: string;
}

export interface IAggregatedGoogle extends IAggregatedGoogleMainTemplate {
  campaigns: IAggregatedGoogleCampaign[];
}

export interface IAggregatedGoogleCampaign extends IAggregatedGoogleSubrowsTemplate {
  googleCampaignId: string;
  adGroups: IAggregatedGoogleAdGroup[];
}

export interface IAggregatedGoogleAdGroup extends IAggregatedGoogleSubrowsTemplate {
  googleAdGroupId: string;
  ads: IAggregatedGoogleAd[];
}

export interface IAggregatedGoogleAd extends IAggregatedGoogleSubrowsTemplate {
  googleAdId: string;
  gAdId: number;
}
