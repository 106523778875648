import React from "react";

import { Icon } from "../components/Icon/Icon";
import {
  AdProviderEnum,
  IAggregatedCampaigns,
  IAggregatedFbAd,
  IAggregatedFbAdset,
  IAggregatedFbCampaign,
  IAggregatedGoogleAd,
  IAggregatedGoogleAdGroup,
  IAggregatedGoogleCampaign,
} from "../redux/types";

export const transformCampaignsData = (campaigns: IAggregatedCampaigns): any[] => {
  const { organic, facebook, google, tiktok } = campaigns;
  const rows: any[] = [];

  const addOrganicRows = () => {
    const organicRow = {
      ...organic,
      src: <Icon name="organic" />,
      name: "Organic Search",
      nameEnum: AdProviderEnum.ORGANIC,
      sumFbSpend: null,
      sumFbImpressions: null,
      sumFbCPM: null,
      sumFbClicks: null,
      sumFbCPC: null,
      sumFbCTR: null,
      sumFbOmniPurchase: null,
      sumFbOmniPurchaseValue: null,
      fbRoas: null,
      sumSpend: null,
      sumImpressions: null,
      sumCPMFL: null,
      sumClicks: null,
      sumFraudClicks: null,
      sumCPCFL: null,
      sumCTRFL: null,
      sumPurchases: null,
      sumPurchasesValue: null,
      roas: null,
      reportedVsRevenue: null,
    };
    rows.push(organicRow);
  };

  const addFacebookRows = () => {
    const facebookRows = {
      ...facebook,
      name: "Facebook Ads",
      nameEnum: AdProviderEnum.FACEBOOK,
      sumFbSpend: facebook.sumFbSpend,
      sumFbImpressions: facebook.sumFbImpressions,
      sumFbCPM: facebook.sumFbCPM,
      sumFbClicks: facebook.sumFbClicks,
      sumFbCPC: facebook.sumFbCPC,
      sumFbCTR: facebook.sumFbCTR,
      sumFbOmniPurchase: facebook.sumFbOmniPurchase,
      sumFbOmniPurchaseValue: facebook.sumFbOmniPurchaseValue,
      fbRoas: facebook.roas,
      sumSpend: facebook.sumFbSpend,
      sumImpressions: facebook.sumFbImpressions,
      sumCPMFL: facebook.sumCPMFL,
      sumClicks: GetFraudClicks(facebook.sumFraudClicks, facebook.sumFbClicks),
      sumFraudClicks: facebook.sumFraudClicks,
      sumCPCFL: facebook.sumCPCFL,
      sumCTRFL: facebook.sumCTRFL,
      sumPurchase: facebook.sumPurchases,
      sumPurchaseValue: facebook.sumPurchasesValue,
      roas: facebook.roasFL,
      reportedVsRevenue: getRevenueDifference(facebook.sumPurchasesValue, facebook.sumFbOmniPurchaseValue),
      src: <Icon name={"facebook" /*row.src*/} />,
      subRows: getSubRowsObject(facebook.campaigns, AdProviderEnum.FACEBOOK),
    };
    rows.push(facebookRows);
  };

  const addGoogleRows = () => {
    const googleRows = {
      src: <Icon name="ico-googleads-big" width="30" />,
      name: "Google Ads",
      nameEnum: AdProviderEnum.GOOGLE,
      sumFbSpend: google.sumGoogleSpent,
      sumFbImpressions: google.sumGoogleImpressions,
      sumFbCPM: google.sumGoogleCPM,
      sumFbClicks: google.sumGoogleClicks,
      sumFbCPC: google.sumGoogleCPC,
      sumFbCTR: google.sumGoogleCTR,
      sumFbOmniPurchase: google.sumGooglePurchases,
      sumFbOmniPurchaseValue: google.sumGooglePurchasesValue,
      fbRoas: google.roas,
      sumSpend: google.sumGoogleSpent,
      sumImpressions: google.sumGoogleImpressions,
      sumCPMFL: google.cpmFL,
      sumClicks: GetFraudClicks(google.sumFraudClicks, google.sumGoogleClicks),
      sumFraudClicks: google.sumFraudClicks,
      sumCPCFL: google.cpcFL,
      sumCTRFL: google.ctrFL,
      sumPurchases: google.sumPurchases,
      sumPurchasesValue: google.sumPurchasesValue,
      roas: google.roasFL,
      reportedVsRevenue: getRevenueDifference(google.sumPurchasesValue, google.sumGooglePurchasesValue),
      subRows: getSubRowsObject(google.campaigns, AdProviderEnum.GOOGLE),
    };
    rows.push(googleRows);
  };

  const addTiktokRows = () => {
    const tiktokRows = {
      ...tiktok,
      src: <Icon name="ico-tiktok-big" width="30" />,
      name: "TikTok Ads",
      nameEnum: AdProviderEnum.TIKTOK,
      sumFbCPM: tiktok.sumFbCPM,
      sumFbCPC: tiktok.sumFbCPC,
      sumFbCTR: tiktok.sumFbCTR,
      roas: getRoas(tiktok.sumPurchasesValue, tiktok.sumFbSpend),
      fbRoas: getRoas(tiktok.sumFbOmniPurchaseValue, tiktok.sumFbSpend),
      reportedVsRevenue: getRevenueDifference(tiktok.sumPurchasesValue, tiktok.sumFbOmniPurchaseValue),
      subRows: getSubRowsObject(tiktok.ads, AdProviderEnum.TIKTOK),
    };

    rows.push(tiktokRows);
  };

  for (const campaignName in campaigns) {
    if (campaignName === AdProviderEnum.ORGANIC) {
      addOrganicRows();
    }
    if (campaignName === AdProviderEnum.FACEBOOK) {
      addFacebookRows();
    }
    if (campaignName === AdProviderEnum.GOOGLE) {
      addGoogleRows();
    }
    if (campaignName === AdProviderEnum.TIKTOK) {
      addTiktokRows();
    }
  }

  return rows;
};

const getSubRowsObject = (firstLevelSubRowArray: any[], nameEnum: AdProviderEnum) => {
  let subRowsObject: any;

  if (nameEnum === AdProviderEnum.FACEBOOK) {
    subRowsObject = firstLevelSubRowArray.map((row: IAggregatedFbCampaign) => ({
      ...row,
      name: row.name || row.facebookId,
      src: <Icon name={"facebook" /*row.src*/} />,
      sumFbSpend: row.sumFbSpend,
      sumFbImpressions: row.sumFbImpressions,
      sumFbCPM: row.sumFbCPM,
      sumFbClicks: row.sumFbClicks,
      sumFbCPC: row.sumFbCPC,
      sumFbCTR: row.sumFbCTR,
      sumFbOmniPurchase: row.sumFbOmniPurchase,
      sumFbOmniPurchaseValue: row.sumFbOmniPurchaseValue,
      fbRoas: row.roas,
      sumSpend: row.sumFbSpend,
      sumImpressions: row.sumFbImpressions,
      sumCPMFL: row.cpmFL,
      sumClicks: GetFraudClicks(row.sumFraudClicks, row.sumFbClicks),
      sumFraudClicks: row.sumFraudClicks,
      sumCPCFL: row.cpcFL,
      sumCTRFL: row.ctrFL,
      sumPurchases: row.sumPurchases,
      sumPurchasesValue: row.sumPurchasesValue,
      roas: row.roasFL,
      reportedVsRevenue: getRevenueDifference(row.sumPurchasesValue, row.sumFbOmniPurchaseValue),
      subRows: row.adsets?.map((adset: IAggregatedFbAdset) => ({
        ...adset,
        name: adset.name || adset.id,
        sumFbSpend: adset.sumFbSpend,
        sumFbImpressions: adset.sumFbImpressions,
        sumFbCPM: adset.sumFbCPM,
        sumFbClicks: adset.sumFbClicks,
        sumFbCPC: adset.sumFbCPC,
        sumFbCTR: adset.sumFbCTR,
        sumFbOmniPurchase: adset.sumFbOmniPurchase,
        sumFbOmniPurchaseValue: adset.sumFbOmniPurchaseValue,
        fbRoas: adset.roas,
        sumSpend: adset.sumFbSpend,
        sumImpressions: adset.sumFbImpressions,
        sumCPMFL: adset.cpmFL,
        sumClicks: GetFraudClicks(adset.sumFraudClicks, adset.sumFbClicks),
        sumFraudClicks: adset.sumFraudClicks,
        sumCPCFL: adset.cpcFL,
        sumCTRFL: adset.ctrFL,
        sumPurchases: adset.sumPurchases,
        sumPurchasesValue: adset.sumPurchasesValue,
        roas: adset.roasFL,
        reportedVsRevenue: getRevenueDifference(adset.sumPurchasesValue, adset.sumFbOmniPurchaseValue),
        subRows: adset.ads?.map((ad: IAggregatedFbAd) => ({
          ...ad,
          src: <Icon name={"productSample"} />,
          name: ad.name || ad.facebookId,
          sumFbSpend: ad.fbSpend,
          sumFbImpressions: ad.fbImpressions,
          sumFbCPM: ad.fbCPM,
          sumFbClicks: ad.fbClicks,
          sumFbCPC: ad.fbCPC,
          sumFbCTR: ad.fbCTR,
          sumFbOmniPurchase: ad.fbOmniPurchase,
          sumFbOmniPurchaseValue: ad.fbOmniPurchaseValue,
          fbRoas: ad.roas,
          sumSpend: ad.fbSpend,
          sumImpressions: ad.fbImpressions,
          sumCPMFL: ad.CPM,
          sumClicks: GetFraudClicks(ad.fraudClicks, ad.fbClicks),
          sumFraudClicks: ad.fraudClicks,
          sumCPCFL: ad.CPC,
          sumCTRFL: ad.CTR,
          sumPurchases: ad.purchases,
          sumPurchasesValue: ad.purchasesValue,
          roas: ad.roasFL,
          reportedVsRevenue: getRevenueDifference(ad.purchasesValue, ad.fbOmniPurchaseValue),
        })),
      })),
    }));
  }

  if (nameEnum === AdProviderEnum.GOOGLE) {
    subRowsObject = firstLevelSubRowArray.map((campaign: IAggregatedGoogleCampaign) => ({
      ...campaign,
      name: campaign.name || campaign.googleCampaignId,
      src: <Icon name={"productSample"} />,
      sumFbSpend: campaign.sumGoogleSpent,
      sumFbImpressions: campaign.sumGoogleImpressions,
      sumFbCPM: campaign.sumGoogleCPM,
      sumFbClicks: campaign.sumGoogleClicks,
      sumFbCPC: campaign.sumGoogleCPC,
      sumFbCTR: campaign.sumGoogleCTR,
      sumFbOmniPurchase: campaign.sumGooglePurchases,
      sumFbOmniPurchaseValue: campaign.sumGooglePurchasesValue,
      fbRoas: campaign.roas,
      sumSpend: campaign.sumGoogleSpent,
      sumImpressions: campaign.sumGoogleImpressions,
      sumCPMFL: campaign.cpmFL,
      sumClicks: GetFraudClicks(campaign.sumFraudClicks, campaign.sumGoogleClicks),
      sumFraudClicks: campaign.sumFraudClicks,
      sumCPCFL: campaign.cpcFL,
      sumCTRFL: campaign.ctrFL,
      sumPurchases: campaign.sumPurchases,
      sumPurchasesValue: campaign.sumPurchasesValue,
      roas: campaign.roasFL,
      reportedVsRevenue: getRevenueDifference(campaign.sumPurchasesValue, campaign.sumGooglePurchasesValue),
      subRows: campaign.adGroups?.map((adGroup: IAggregatedGoogleAdGroup) => ({
        ...adGroup,
        name: adGroup.name || adGroup.googleAdGroupId,
        sumFbSpend: adGroup.sumGoogleSpent,
        sumFbImpressions: adGroup.sumGoogleImpressions,
        sumFbCPM: adGroup.sumGoogleCPM,
        sumFbClicks: adGroup.sumGoogleClicks,
        sumFbCPC: adGroup.sumGoogleCPC,
        sumFbCTR: adGroup.sumGoogleCTR,
        sumFbOmniPurchase: adGroup.sumGooglePurchases,
        sumFbOmniPurchaseValue: adGroup.sumGooglePurchasesValue,
        fbRoas: adGroup.roas,
        sumSpend: adGroup.sumGoogleSpent,
        sumImpressions: adGroup.sumGoogleImpressions,
        sumCPMFL: adGroup.cpmFL,
        sumClicks: GetFraudClicks(adGroup.sumFraudClicks, adGroup.sumGoogleClicks),
        sumFraudClicks: adGroup.sumFraudClicks,
        sumCPCFL: adGroup.cpcFL,
        sumCTRFL: adGroup.ctrFL,
        sumPurchases: adGroup.sumPurchases,
        sumPurchasesValue: adGroup.sumPurchasesValue,
        roas: adGroup.roasFL,
        reportedVsRevenue: getRevenueDifference(adGroup.sumPurchasesValue, adGroup.sumGooglePurchasesValue),
        subRows: adGroup.ads?.map((ad: IAggregatedGoogleAd) => ({
          ...ad,
          src: <Icon name={"productSample"} />,
          name: ad.name || ad.googleAdId,
          sumFbSpend: ad.sumGoogleSpent,
          sumFbImpressions: ad.sumGoogleImpressions,
          sumFbCPM: ad.sumGoogleCPM,
          sumFbClicks: ad.sumGoogleClicks,
          sumFbCPC: ad.sumGoogleCPC,
          sumFbCTR: ad.sumGoogleCTR,
          sumFbOmniPurchase: ad.sumGooglePurchases,
          sumFbOmniPurchaseValue: ad.sumGooglePurchasesValue,
          fbRoas: ad.roas,
          sumSpend: ad.sumSpent,
          sumImpressions: ad.sumImpressions,
          cpmFL: ad.cpmFL,
          sumClicks: GetFraudClicks(ad.sumFraudClicks, ad.sumGoogleClicks),
          sumFraudClicks: ad.sumFraudClicks,
          cpcFL: ad.cpcFL,
          ctrFL: ad.ctrFL,
          sumPurchases: ad.sumPurchases,
          sumPurchasesValue: ad.sumPurchasesValue,
          roas: ad.roasFL,
          reportedVsRevenue: getRevenueDifference(ad.sumPurchasesValue, ad.sumGooglePurchasesValue),
        })),
      })),
    }));
  }
  if (nameEnum === AdProviderEnum.TIKTOK) {
    subRowsObject = firstLevelSubRowArray.map((ad) => ({
      ...ad,
      name: ad.tiktokId,
      sumFbCPM: ad.sumFbCPM,
      sumFbCPC: ad.sumFbCPC,
      sumFbCTR: ad.sumFbCTR,
      roas: getRoas(ad.sumPurchasesValue, ad.sumFbSpend),
      fbRoas: getRoas(ad.sumFbOmniPurchaseValue, ad.sumFbSpend),
      reportedVsRevenue: getRevenueDifference(ad.sumPurchasesValue, ad.sumFbOmniPurchaseValue),
      src: <Icon name="productSample" />,
    }));
  }

  return subRowsObject;
};

export const GetFraudClicks = (sumFraudClicks: number | null, sumFbClicks: number | null): string | number => {
  if (sumFbClicks !== null && sumFraudClicks !== null) {
    const fraudClicks = sumFbClicks - sumFraudClicks;
    return `${fraudClicks || 0}`;
  }
  return "-";
};

export const getRoas = (sumPurchasesValue: number | null, sumProviderSpend: number | null): string | number => {
  if (sumPurchasesValue !== null && sumProviderSpend && sumProviderSpend !== 0) {
    return (sumPurchasesValue / sumProviderSpend) * 100;
  }
  return "-";
};

const getRevenueDifference = (
  sumPurchasesValue: number | null | undefined,
  sumProviderPurchasesValue: number | null | undefined,
): number | string => {
  let revenueDifference: string | number = "-";

  if (typeof sumPurchasesValue === "number" && typeof sumProviderPurchasesValue === "number") {
    revenueDifference = sumPurchasesValue - sumProviderPurchasesValue;
  }

  return revenueDifference;
};
