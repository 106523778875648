import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { useGetCampaignsFromCurrentDetails } from "../../../api/views";
import { Table } from "../../../components/Table/Table";
import { useGetBreakpointProperties } from "../../../hooks/screen/useGetBreakpointProperties";
import { getCampaigns, getTemporaryCampaignCols } from "../../../redux/selectors/campaigns";
import { TableType } from "../../../redux/types/table";
import { useGetFormattedColumnValue } from "./getFormattedColumnValue";
import { getRowClassName } from "./getRowClassName";

import styles from "./CampaignsTable.module.scss";

export const CampaignTable: React.FC = () => {
  const { isDesktop, isLargeDesktop } = useGetBreakpointProperties();
  const campaignsData = useSelector(getCampaigns);
  const { isLoading } = useGetCampaignsFromCurrentDetails();
  const temporarySelectedCols = useSelector(getTemporaryCampaignCols);
  const isDataLoading = isLoading || campaignsData.length === 0;

  const [showScrollLeftTextMedia, setShowScrollLeftTextMedia] = useState(false);
  const [showScrollRightTextMedia, setShowScrollRightTextMedia] = useState(false);
  const [showScrollLeftTextQiriq, setShowScrollLeftTextQiriq] = useState(false);
  const [showScrollRightTextQiriq, setShowScrollRightTextQiriq] = useState(false);
  const mediaTableRef = useRef<HTMLDivElement>(null);
  const qiriqTableRef = useRef<HTMLDivElement>(null);

  const mediaTableAccessors = [
    "src",
    "name",
    "sumFbSpend",
    "sumFbImpressions",
    "sumFbCPM",
    "sumFbClicks",
    "sumFbCPC",
    "sumFbCTR",
    "sumFbOmniPurchase",
    "sumFbOmniPurchaseValue",
    "fbRoas",
  ];

  const qiriqMediaTableAccessors = [
    "src",
    "name",
    "sumSpend",
    "sumImpressions",
    "sumCPMFL",
    "sumClicks",
    "sumFraudClicks",
    "sumCPCFL",
    "sumCTRFL",
    "sumPurchases",
    "sumPurchasesValue",
    "roas",
    "reportedVsRevenue",
  ];

  const mediaTableColumns = temporarySelectedCols.filter((column) => mediaTableAccessors.includes(column.accessor));

  const qiriqMediaTableColumns = temporarySelectedCols.filter((column) =>
    qiriqMediaTableAccessors.includes(column.accessor),
  );

  const filteredMediaColumns = mediaTableColumns.filter((column) =>
    isDesktop || isLargeDesktop ? true : !column.notOnSmallDisplay,
  );

  const filteredQiriqMediaColumns = qiriqMediaTableColumns.filter((column) =>
    isDesktop || isLargeDesktop ? true : !column.notOnSmallDisplay,
  );
  const handleScroll = (
    ref: React.RefObject<HTMLDivElement>,
    setShowScrollLeftText: React.Dispatch<React.SetStateAction<boolean>>,
    setShowScrollRightText: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    if (ref.current) {
      const scrollLeft = ref.current.scrollLeft;
      const maxScrollLeft = ref.current.scrollWidth - ref.current.clientWidth;

      setShowScrollLeftText(scrollLeft > 0);
      setShowScrollRightText(scrollLeft < maxScrollLeft);
    }
  };

  useEffect(() => {
    const handleScrollMediaTable = () =>
      handleScroll(mediaTableRef, setShowScrollLeftTextMedia, setShowScrollRightTextMedia);
    const handleScrollQiriqTable = () =>
      handleScroll(qiriqTableRef, setShowScrollLeftTextQiriq, setShowScrollRightTextQiriq);

    const mediaTableElement = mediaTableRef.current;
    const qiriqTableElement = qiriqTableRef.current;

    if (mediaTableElement) {
      mediaTableElement.addEventListener("scroll", handleScrollMediaTable);
      handleScrollMediaTable();
    }

    if (qiriqTableElement) {
      qiriqTableElement.addEventListener("scroll", handleScrollQiriqTable);
      handleScrollQiriqTable();
    }

    return () => {
      if (mediaTableElement) {
        mediaTableElement.removeEventListener("scroll", handleScrollMediaTable);
      }
      if (qiriqTableElement) {
        qiriqTableElement.removeEventListener("scroll", handleScrollQiriqTable);
      }
    };
  }, []);

  return (
    <>
      <div className={`${styles.header} ${styles.tableHeader1}`}>
        {showScrollLeftTextMedia && <span className={`${styles.scrollIndicator} ${styles.left}`}>←</span>}
        <h3>Ad Network Table</h3>
        {showScrollRightTextMedia && <span className={`${styles.scrollIndicator} ${styles.right}`}>→</span>}
      </div>
      <div ref={mediaTableRef} className={styles.tableContainer}>
        <Table
          cols={filteredMediaColumns}
          data={campaignsData}
          loading={isDataLoading}
          defaultSortBy={{ id: "sumPurchasesValue", desc: true }}
          sortBy={[
            { id: "sumFbSpend", desc: false },
            { id: "sumFbImpressions", desc: false },
            { id: "sumFbCPM", desc: false },
            { id: "sumFbClicks", desc: false },
            { id: "sumFbCPC", desc: false },
            { id: "sumFbCTR", desc: false },
            { id: "sumFbOmniPurchase", desc: false },
            { id: "sumFbOmniPurchaseValue", desc: false },
            { id: "fbRoas", desc: false },
          ]}
          getColumnValue={useGetFormattedColumnValue}
          getRowClass={getRowClassName}
          tableType={TableType.CAMPAIGNS}
        />
      </div>
      <div className={`${styles.header} ${styles.tableHeader2}`}>
        {showScrollLeftTextQiriq && <span className={`${styles.scrollIndicator} ${styles.left}`}>←</span>}
        <h3>Qiriq Ad Truth Table</h3>
        {showScrollRightTextQiriq && <span className={`${styles.scrollIndicator} ${styles.right}`}>→</span>}
      </div>
      <div ref={qiriqTableRef} className={styles.tableContainer}>
        <Table
          cols={filteredQiriqMediaColumns}
          data={campaignsData}
          loading={isDataLoading}
          defaultSortBy={{ id: "sumPurchasesValue", desc: true }}
          sortBy={[
            { id: "sumSpend", desc: false },
            { id: "sumImpressions", desc: false },
            { id: "sumCPMFL", desc: false },
            { id: "sumClicks", desc: false },
            { id: "sumFraudClicks", desc: false },
            { id: "sumCPCFL", desc: false },
            { id: "sumCTRFL", desc: false },
            { id: "sumPurchases", desc: false },
            { id: "sumPurchasesValue", desc: false },
            { id: "roas", desc: false },
            { id: "reportedVsRevenue", desc: false },
          ]}
          getColumnValue={useGetFormattedColumnValue}
          getRowClass={getRowClassName}
          tableType={TableType.CAMPAIGNS}
        />
      </div>
    </>
  );
};
