import { SelectOption } from "../../components/Select/Select";
import { CurrencyType } from "../../redux/types";
import { currencyData } from "../constants/currencies";

export const getFormattedPrice = (price: number, currency = CurrencyType.USD): string => {
  const fractionDigits = price > 1_000_000 ? 0 : 2;
  const formatter = new Intl.NumberFormat(navigator.language, {
    style: "currency",
    currency,
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
    currencyDisplay: "narrowSymbol",
  });
  return formatter.format(Number(price));
};
export const getCTRPercentage = (value: number): string => {
  if (isNaN(value) || value === null || value === undefined) {
    return "-";
  }
  const formattedValue = (value * 100).toFixed(2);
  alert(`CTR Value: ${formattedValue}%`); // Debugging alert

  return `${formattedValue}%`;
};

export const getRoasMultiple = (value: number): string => {
  let stringEnd = "";
  if (value !== 0) {
    stringEnd = "x";
  }
  const roundedValueMultiple = Math.round(value * 100) / 100;
  const formattedRoundedValueMultiple = Math.round(roundedValueMultiple) / 100;
  return `${formattedRoundedValueMultiple}${stringEnd}`;
};

export const getCurrencyOption = (code: CurrencyType): SelectOption => ({
  label: `${getCurrencyDataFromCode(code)?.currency} (${code})`,
  value: code,
});

export const getCurrencyDataFromCode = (code: CurrencyType) => {
  const upperCaseCode = code.toUpperCase();
  return currencyData.find((c) => c.code === upperCaseCode);
};
